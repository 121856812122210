import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Utils
import useOutsideClick from '../../../../../utils/useOutsideClick';

// Icons
import CheckIcon from '../../../../../icons/check.svg';

// Utils
import { getStopListSauceReplacements } from '../../../../../utils/cart';

// Styles
import styles from './SaucesList.styl';

const cx = classNames.bind(styles);

export default function SaucesList(props) {
  const { isOpen, onHide, className, sauces, disabled, addFreeSauce, stopList } = props;

  const nodeRef = useOutsideClick(onHide, isOpen);

  if (!isOpen || disabled) {
    return null;
  }

  return (
    <div className={cx('SaucesList', className)} ref={nodeRef} data-test-id="sauces_list">
      {sauces.map(sauce => {
        const { variations, name } = sauce;
        const { id } = variations[0];
        if (getStopListSauceReplacements(stopList, variations[0])) {
          return null;
        }

        return (
          <button
            key={name}
            className={cx('SaucesList__item')}
            onClick={() => {
              addFreeSauce(id);
            }}
            type="button"
          >
            <CheckIcon className={cx('SaucesList__check')} />
            <div className={cx('SaucesList__name')}>{name}</div>
          </button>
        );
      })}
    </div>
  );
}

SaucesList.defaultProps = {
  className: '',
  isOpen: false,
  disabled: false,
  stopList: [],
};

SaucesList.propTypes = {
  className: PropTypes.string,
  sauces: PropTypes.array.isRequired,
  stopList: PropTypes.array,
  addFreeSauce: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};
