import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

// Components
import Heading from '../../../Heading';

// Icons
import GiftIcon from '../../../../icons/gift.svg';
import RemoveIcon from '../../../../icons/close.svg';

// Utils
import { getStopListSauceReplacements } from '../../../../utils/cart';

// Styles
import styles from './AddedSauce.styl';

const cx = classNames.bind(styles);

export default function AddedSauce(props) {
  const { className, editable, onRemove, sauce, stopList } = props;
  const { id, name, category } = sauce;
  const intl = useIntl();
  const isItStopList = Boolean(getStopListSauceReplacements(stopList, sauce));

  const handleRemove = () => {
    onRemove(id);
  };

  return (
    <div
      className={cx('AddedSauce', className, { AddedSauce_outOfStock: isItStopList })}
      data-test-id="added_sauce"
    >
      <Heading className={cx('AddedSauce__text')} level={5}>
        {isItStopList ? intl.formatMessage({ id: 'freesauce.outOfStock' }) : `+ ${name}`}
      </Heading>
      {!isItStopList && <GiftIcon className={cx('AddedSauce__gift')} />}
      {editable && (
        <button className={cx('AddedSauce__remove')} onClick={handleRemove} type="button">
          {isItStopList ? intl.formatMessage({ id: 'button.replace' }) : <RemoveIcon />}
        </button>
      )}
    </div>
  );
}

AddedSauce.defaultProps = {
  className: '',
  editable: true,
  stopList: [],
};

AddedSauce.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  sauce: PropTypes.object.isRequired,
  stopList: PropTypes.array,
};
