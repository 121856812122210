import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// Components
import Heading from '../../../Heading';
import ToggleArrow from '../../../ToggleArrow';
import DesktopView from './DesktopView';
import MobileView from './MobileView';

// Styles
import styles from './Sauces.styl';

const cx = classNames.bind(styles);

export default function Sauces(props) {
  const { className, sauces, disabled, addFreeSauce, stopList } = props;

  const intl = useIntl();
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);
  const [isOpen, setIsOpen] = useState(false);
  const handleOnToggle = () => {
    setIsOpen(prev => !prev);
  };

  const handleOnHide = () => {
    setIsOpen(false);
  };

  const View = isRealPhone ? MobileView : DesktopView;
  return (
    <div className={cx('Sauces', className)} data-test-id="add_sauce">
      <Heading level={5} className={cx('Sauces__toggle')} onClick={handleOnToggle}>
        + {intl.formatMessage({ id: 'freeSauces.add' })}
        <ToggleArrow className={cx('Sauces__toggle-arrow')} isOpen={isOpen} />
      </Heading>
      <View
        sauces={sauces}
        disabled={disabled}
        addFreeSauce={addFreeSauce}
        isOpen={isOpen}
        onHide={handleOnHide}
        stopList={stopList}
      />
    </div>
  );
}

Sauces.defaultProps = {
  className: '',
  stopList: [],
};

Sauces.propTypes = {
  className: PropTypes.string,
  sauces: PropTypes.array.isRequired,
  stopList: PropTypes.array,
  addFreeSauce: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
